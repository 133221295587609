import { fundingRepaymentPeriodYearsOptions, fundingRepaymentPeriodMonthOptions } from '@/utils/constants'
import config from '@/config/global'
import dictionary from '@/config/dictionary'

export default {
  name: 'financialInformationForm',
  routeName: 'financial-information',
  formTitle: 'Financial Information',
  navTitle: 'Financial Information',
  completed: null,
  maxGroupColumns: 12,
  groups: [
    {
      title: 'Financial data',
      fields: [
        [
          {
            field: 'input',
            name: 'isEstablishedLessOneYear',
            isCountable: false,
            selected: false
          }
        ],
        [
          {
            field: 'dummy',
            name: 'fundingRepaymentPeriod',
            label: 'What is your preferred repayment period for loan funding?'
          }
        ],
        [
          {
            choice: 'single',
            field: 'select',
            type: '',
            isInline: true,
            name: 'fundingRepaymentPeriodYears',
            veeAs: 'preferred repayment period',
            label: '',
            placeholder: 'years',
            rules: 'required|numeric',
            options: fundingRepaymentPeriodYearsOptions,
            selected: ''
          },
          {
            choice: 'single',
            field: 'select',
            type: '',
            isInline: true,
            name: 'fundingRepaymentPeriodMonth',
            veeAs: 'preferred repayment period',
            label: '',
            placeholder: 'months',
            rules: 'required|numeric|required_if_falsy:fundingRepaymentPeriodYears,0',
            options: fundingRepaymentPeriodMonthOptions,
            dependsOn: [
              {
                fieldName: 'fundingRepaymentPeriodYears',
                rule(selected) {
                  return String(selected) !== '10'
                }
              }
            ],
            selected: ''
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'number',
            name: 'estimatedTurnover',
            veeAs: 'estimated turnover',
            label: `What is your estimated ${dictionary.turnover} this year?`,
            placeholder: '',
            rules: 'required|decimal|min_value:1',
            icon: {
              text: config.whitelabel.country.currency.defaultCurrencySymbol,
              position: 'left'
            },
            dependsOn: [
              {
                fieldName: 'isEstablishedLessOneYear',
                values: [true]
              }
            ],
            selected: ''
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'number',
            name: 'turnoverLastYear',
            veeAs: 'turnover last year',
            label: `What was the ${dictionary.turnover} in the last financial year?`,
            placeholder: '',
            rules: 'required|decimal|min_value:0',
            icon: {
              text: config.whitelabel.country.currency.defaultCurrencySymbol,
              position: 'left'
            },
            dependsOn: [
              {
                fieldName: 'isEstablishedLessOneYear',
                values: [false]
              }
            ],
            selected: ''
          },
          {
            choice: 'single',
            field: 'input',
            type: 'number',
            name: 'currentCustomersNumber',
            veeAs: 'current customers number',
            label: 'How many customers do you have? (put 0 if not trading)',
            placeholder: '0',
            rules: 'required|numeric|min_value:0',
            selected: ''
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'number',
            name: 'monthlyBusinessDebtRepayments',
            veeAs: ' ',
            label: 'What is your monthly business debt repayments?',
            placeholder: '',
            rules: 'required|decimal|min_value:0',
            icon: {
              text: config.whitelabel.country.currency.defaultCurrencySymbol,
              position: 'left'
            },
            selected: ''
          },
          {
            choice: 'single',
            field: 'input',
            type: 'number',
            name: 'existingBusinessOverdraft',
            veeAs: ' ',
            label: 'What is your existing business overdraft?',
            placeholder: '',
            rules: 'required|decimal|min_value:0',
            icon: {
              text: config.whitelabel.country.currency.defaultCurrencySymbol,
              position: 'left'
            },
            selected: ''
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'number',
            name: 'totalBusinessBorrowingRepayments',
            veeAs: ' ',
            label: 'Total Business Borrowing Repayments',
            placeholder: '',
            rules: 'required|decimal|min_value:0',
            icon: {
              text: config.whitelabel.country.currency.defaultCurrencySymbol,
              position: 'left'
            },
            selected: ''
          },
          {
            field: 'dummy'
          }
        ]
      ]
    }
  ]
}
