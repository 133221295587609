<template>
  <div class="ui stackable container grid row companyprofile">
    <nav class="four wide column col-xs-12 col-sm-12 col-md-4 col-lg-4">
      <BackBtn />
      <h2 class="navigation-title h2">{{ financialInformationForm.navTitle }}</h2>
      <ul class="list-group">
        <li>
          <a class="list-group-item-navigator router-link-active" :title="financialInformationForm.navTitle">
            <span class="text">{{ financialInformationForm.navTitle }}</span>
            <template v-if="!isLoading">
              <span class="complete-icon" v-if="financialInformationForm.completed">
                <i class="material-icons">check</i>
              </span>
              <span class="progress" v-else>
                <template class="progress" v-if="stepProgress">{{ stepProgress.completed }}/{{ stepProgress.total }}</template>
              </span>
            </template>
          </a>
        </li>
      </ul>
    </nav>
    <section class="twelve wide column col-xs-12 col-sm-12 col-md-8 col-lg-8">
      <div class="row">
        <header class="col-xs-12 col-md-12">
          <h3 class="h1 form-title">{{ financialInformationForm.formTitle }}</h3>
        </header>
        <div class="col-xs-12 col-md-12 form-company-inner">
          <div class="align-form" v-if="isLoading">
            <div class="ui active inverted dimmer page-loader">
              <div class="ui medium text loader"><spinner :size="64" :loader="true" color /></div>
            </div>
          </div>
          <DynamicForm
            class="out-form align-form company-profile-form"
            v-model="financialInformationForm"
            :submit-promise="submitPromise"
            submit-text="Save"
            @df-submit="submitFinancialInformationForm"
            v-else
          >
            <template #isEstablishedLessOneYear><div></div></template>
            <template #fundingRepaymentPeriod="{ field }">
              <div class="field funding-repayment-period-field">
                <label>{{ field.label }}</label>
              </div>
            </template>
          </DynamicForm>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import deepcopy from 'deepcopy'
import { mapGetters } from 'vuex'
import BackBtn from '@/components/BackBtn'
import DynamicForm from '@/components/forms/DynamicForm'
import financialInformationForm from './financialInformation.form'
import { companyGetters } from '@/store/modules/company/routines'
import { fundingGetters } from '@/store/routines'
import { getFinancialData, setFinancialDataEligibility } from '@/api/financialData'
import { getFundingApplication } from '@/api/fundingApplication'
import { prefillFormData, getStepProgress } from '@/utils/formHelpers'
import { toastDefaultOptions } from '@/config/vue-toast'

export default {
  name: 'FinancialInformation',
  components: {
    BackBtn,
    DynamicForm
  },
  data() {
    return {
      isLoading: true,
      financialInformationForm: deepcopy(financialInformationForm),
      submitPromise: null
    }
  },
  computed: {
    ...mapGetters({
      companyId: companyGetters.COMPANY_ID,
      fundingApplicationId: fundingGetters.FUNDING_APP_ID
    }),
    stepProgress() {
      return getStepProgress(this.financialInformationForm)
    }
  },
  methods: {
    async prefillFinancialInformation() {
      try {
        const [financialDataRes, fundingApplicationRes] = await Promise.all([getFinancialData(this.companyId), getFundingApplication(this.fundingApplicationId)])
        const { fundingRepaymentPeriod, ...fundingApplicationData } = fundingApplicationRes.data

        const fpPeriod = parseInt(fundingRepaymentPeriod, 10) || 0
        const fundingRepaymentPeriodMonth = fpPeriod % 12
        const fundingRepaymentPeriodYears = (fpPeriod - fundingRepaymentPeriodMonth) / 12

        const formInitialValues = {
          ...financialDataRes.data,
          ...fundingApplicationData,
          fundingRepaymentPeriodMonth,
          fundingRepaymentPeriodYears
        }
        prefillFormData(this.financialInformationForm, formInitialValues)
        if (this.stepProgress.completed === this.stepProgress.total) {
          this.financialInformationForm.completed = true
        }
      } catch (e) {
        this.$toasted.show('Unable pre populate form', { ...toastDefaultOptions, type: 'error', icon: 'error' })
      }
    },
    submitFinancialInformationForm(form) {
      const { companyId } = this
      const { fundingRepaymentPeriodYears = null, fundingRepaymentPeriodMonth = null, ...formData } = form.data
      let fundingRepaymentPeriod = null
      if (fundingRepaymentPeriodYears !== null && fundingRepaymentPeriodMonth !== null) {
        const fpYears = fundingRepaymentPeriodYears ? parseInt(fundingRepaymentPeriodYears, 10) : 0
        const fpMonths = fundingRepaymentPeriodMonth ? parseInt(fundingRepaymentPeriodMonth, 10) : 0
        fundingRepaymentPeriod = fpYears * 12 + fpMonths
      }
      this.submitPromise = setFinancialDataEligibility({
        ...formData,
        companyId,
        fundingRepaymentPeriod
      }).then(() => {
        this.$router.go(-1)
      })
      return this.submitPromise
    }
  },
  async created() {
    this.isLoading = true
    await this.prefillFinancialInformation()
    this.isLoading = false
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/swoop/variables';
.ui.companyprofile {
  .twelve.wide.column {
    background-color: #ffffff;
  }
}
.navigation-title {
  font-weight: 400;
}
.progress-bar {
  margin: 0 -32px;
}

.company-profile-form {
  /deep/ {
    .form-group-title {
      color: var(--color-primary-500);
    }
    .form-group-title,
    .form-group-content {
      text-align: left;
    }
    .action-container {
      display: flex;
      flex-direction: column;
      @media only screen and (max-width: 770px) {
        align-items: center;
      }
      .form-submit-btn {
        max-width: 196px;
        margin-bottom: 1rem;
      }
    }
  }
}

.form-company-container {
  background-color: $color-white;
}

.form-company-inner {
  .align-form {
    // margin-left: 130px;
    margin: 2rem;
    @media only screen and (max-width: 770px) {
      margin: 1rem;
    }

    .fields {
      @media only screen and (max-width: 770px) {
        margin: 1rem 0;
      }
    }
  }
}

.inner-title {
  font-size: $fs-lg-mobile;
  line-height: $lh-lg-mobile;
  padding: 14px 0 34px 0;
  font-weight: normal;
}

.btn-add-company-owner {
  margin: 0 auto;
}
.ui.companyprofile {
  .btn-back {
    margin: 2rem 0 2rem;
  }

  a {
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
    &.list-group-item-navigator {
      margin-bottom: 8px;
      @media only screen and (min-width: 770px) {
        width: 292px;
      }
    }
  }
}

section {
  .ui.form .field {
    .ui.label {
      text-align: left;
    }
    &.funding-repayment-period-field {
      margin-bottom: -0.5rem;
    }
  }
  .form-title {
    padding: 2rem;
    font-size: 20px;
    @media only screen and (max-width: 770px) {
      padding: 2rem 1rem;
    }
  }
}
</style>
